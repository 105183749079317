var generic = generic || {};
var site = site || {};
var settings = Drupal.settings || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};
(function ($, Drupal) {
  'use strict';

  var isMobileWidth = true;
  var isLoyaltyPricesEnabled = Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled;
  // Modernizr touch + not firefox
  var isTouchDevice = Modernizr.touch && navigator.userAgent.toLowerCase().indexOf('firefox') <= -1;
  var drupalSettings = Drupal.settings && Drupal.settings.bb_product;
  var isSpendBasedEnabled = drupalSettings && Drupal.settings.bb_product.spend_based_points;

  function Navigation($elem) {
    this.$menu = $elem;
    this.activeCategoryObject;

    $('.js-header-search-close', this.$nav).off('click.searchClose').on('click.searchClose', function (event) {
      event.preventDefault();
      $('.typeahead-wrapper', this.$nav).addClass('hidden');
    });
    // Loop through and create each category
    var $categories = $('.js-nav-category-trigger', $elem);

    this.attachCategories($categories.filter('.js-nav-category-trigger--top'));
    this.attachSubCategories($categories.filter(':not(.js-nav-category-trigger--top)'));
  }

  Navigation.prototype.attachCategories = function ($categories) {
    var self = this;

    $categories.each(function (index) {
      var activateEvent = isMobileWidth || isTouchDevice ? 'click.gnav' : 'mouseenter.gnav';
      // Events to off if we switch breakpoints
      var deactivateEvent = isMobileWidth || isTouchDevice ? 'mouseenter.gnav' : 'click.gnav';
      var $category = $categories.eq(index);
      var categoryObject = new NavigationCategory($category, self.$menu);

      // If no links, it's only tout images which means we reduce the spacing
      if (!categoryObject.$categoryDropdown.find('.js-content-block-link, .js-menu-shop-all').length) {
        categoryObject.$categoryDropdown.addClass('no-extra-padding');
      }
      self.addClassTopCategory(categoryObject, $category);
      $category.off(deactivateEvent).on(activateEvent, function (event) {
        if (isMobileWidth || isTouchDevice && !$category.closest('.gnav-section').hasClass('active')) {
          if (!categoryObject.$categoryDropdown.length) {
            return;
          }
          event.preventDefault();
        }
        self.activateCategory(categoryObject);
      });
      categoryObject.$categoryDropdown.on('mouseleave', function (event) {
        if (!isMobileWidth) {
          if (categoryObject.$categoryDropdown.find(event.target).length) {
            return;
          }
          self.deactivateCategory(categoryObject);
        }
      });
      $category.off('keypress.gnav').on('keypress.gnav', function (event) {
        if (site.getKeycode(event) === 13) {
          event.preventDefault();
          self.activateCategory(categoryObject);
        }
      });
    });
  };

  Navigation.prototype.toggleSubCategory = function ($subcategoryTrigger) {
    var $subCategory = $subcategoryTrigger.closest('.js-menu-item-category');
    var $subCategoryDropdown = $subCategory.find('.js-nav-category-trigger-content');

    $subCategoryDropdown.toggleClass('active-dropdown');
    if ($subCategoryDropdown.hasClass('active-dropdown')) {
      $subCategoryDropdown.stop(true, true).slideDown();
    } else {
      $subCategoryDropdown.stop(true, true).slideUp();
    }
    $subCategory.toggleClass('active', $subCategoryDropdown.hasClass('active-dropdown'));
  };

  Navigation.prototype.attachSubCategories = function ($subCategories) {
    if (!isMobileWidth) {
      return;
    }
    var self = this;

    $subCategories.each(function (index) {
      var $subCategory = $subCategories.eq(index);

      $subCategory.off('click.subnav').on('click.subnav', function (event) {
        event.preventDefault();
        self.toggleSubCategory($(this));
      });
      $subCategory.off('keypress.subnav').on('keypress.subnav', function (event) {
        if (site.getKeycode(event) === 13) {
          event.preventDefault();
          self.toggleSubCategory($(this));
        }
      });
      if ($subCategory.closest('.js-menu-item-category').hasClass('js-preactivated')) {
        self.toggleSubCategory($subCategory);
      }
    });
  };

  Navigation.prototype.activateCategory = function (categoryObject) {
    if (this.activeCategoryObject) {
      this.activeCategoryObject.deactivate();
    }
    if (categoryObject === this.activeCategoryObject) {
      this.activeCategoryObject = false;
    } else {
      this.activeCategoryObject = categoryObject;
      this.activeCategoryObject.activate();
    }
  };

  Navigation.prototype.deactivateCategory = function () {
    if (this.activeCategoryObject) {
      this.activeCategoryObject.deactivate();
    }
    this.activeCategoryObject = false;
  };

  Navigation.prototype.addClassTopCategory = function (categoryObject, $category) {
    if (!!isMobileWidth && !categoryObject.$categoryDropdown.length) {
      $category.addClass('gnav-section__link--no-child');
    }
  };

  function NavigationCategory($elem, $nav) {
    this.$category = $elem.parent().parent();
    this.$categoryDropdown = this.$category.find('.js-nav-category-trigger-content');
    this.$nav = $nav;
  }
  NavigationCategory.prototype.activate = function () {
    this.$categoryDropdown.css({ 'top': this.$nav.find('.site-header__main')[0].getBoundingClientRect().bottom });
    this.$categoryDropdown.attr('aria-expanded', true).removeClass('nav-hidden');
    setTimeout(function ($category) {
      $category.addClass('active');
    }, 0, this.$category);
  };
  NavigationCategory.prototype.deactivate = function () {
    this.$categoryDropdown.attr('aria-expanded', false).addClass('nav-hidden');
    this.$category.removeClass('active');
  };

  Drupal.behaviors.siteHeaderV1 = {
    attach: function (context) {
      var $module = $('.js-site-header', context);
      var nonLoyaltyLink,
        loyaltyLink,
        pointsTextContainer;
      var pointsPrefixSpace = '';
      var loyaltyPtsTxt = '';
      var loyaltyPts = '';
      var showLoyaltyPoints = settings.show_points_for_loyalty ? 'points' : 'current_qualifying';

      if (site.client.isMobile) {
        nonLoyaltyLink = $('.js-site-header__block-link--non-loyalty', context);
        loyaltyLink = $('.js-site-header__block-link--loyalty', context);
        pointsTextContainer = $('.js-home_mobile_gnav_loyalty_pts', context);
      } else {
        nonLoyaltyLink = $('.site-utils__item--loyalty--join', context);
        loyaltyLink = $('.site-utils__item--loyalty', context);
        pointsTextContainer = $('.home_gnav_loyalty_pts', context);
        pointsPrefixSpace = '\xa0';
      }
      if (!$module.length) {
        return;
      }
      isMobileWidth = Unison.fetch.now().name === 'small';
      $(window).on('resize', _.debounce(function () {
        isMobileWidth = Unison.fetch.now().name === 'small';
      }, 50));
      var navigation = new Navigation($module);

      $(window).off('scroll.headerCategories').on('scroll.headerCategories', function () {
        if (!navigation.activeCategoryObject) {
          return;
        }
        navigation.activeCategoryObject.$categoryDropdown.fadeOut(200, function () {
          $(this).attr('style', '');
          navigation.deactivateCategory();
        });
      });
      $(document).on('user.loaded', function (event, val) {
        if (!val.is_pro) {
          nonLoyaltyLink.removeClass('hidden');
          if (val.signed_in) {
            if (isLoyaltyPricesEnabled) {
              nonLoyaltyLink.addClass('hidden');
              loyaltyLink.removeClass('hidden');
              var loyaltyTextContainer = $('.home_gnav_loyalty', context);
              var loyaltyText = loyaltyTextContainer.text();
              var firstname = site.userInfoCookie.getValue('first_name') ? site.userInfoCookie.getValue('first_name') + ',' : '';

              loyaltyTextContainer.text(firstname + pointsPrefixSpace + loyaltyText);
            } else if (val.is_loyalty_member) {
              $('.account-links__loyalty', context).removeClass('hidden');
              nonLoyaltyLink.addClass('hidden');
              loyaltyLink.removeClass('hidden');
              loyaltyPtsTxt = pointsTextContainer.text();
              loyaltyPts = isSpendBasedEnabled ? site.userInfoCookie.getValue('current_available') : site.userInfoCookie.getValue(showLoyaltyPoints);
              loyaltyPts = loyaltyPts + pointsPrefixSpace + loyaltyPtsTxt;
              pointsTextContainer.text(loyaltyPts);
            }
          }
        }
      });
    }
  };
})(jQuery, window.Drupal);
